import logo from './shingfailogo.png';
import './App.css';

function App() {
 return (
 <div className="App">
 <header className="App-header">
 <img src={logo} className="App-logo" alt="logo" />
 <p>----盛輝科技為企業提供優質的服務----</p>
<p>---------最佳之選--------</p>
 
 </header>
 </div>
 );
}

export default App;
